import moment from 'moment'

function removeTags(str: string) {
  if (str === null || str === '') return false
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}

function truncate(text: string, clamp: string, length: number) {
  clamp = clamp || '...'
  return text.length > length ? text.slice(0, length) + clamp : text
}

function cleanText(text: string) {
  text = text.replace(/<\/?[^>]+(>|$)/g, '')
  return text.replace(/&nbsp;/g, ' ')
}

function formatJobDate(date: string) {
  return moment(date).format('LL')
}

interface GpsCoordinates {
  lat: string
  lng: string
}

function extractGpsCoordinates(gpsCoordinates): GpsCoordinates {
  let data = gpsCoordinates.split(',').map((str) => parseFloat(str))
  return { lat: data[0], lng: data[1] }
}

function returnYearDate(date: string) {
  return moment(date).format('YYYY')
}

function returnMonthDate(date: string) {
  return moment(date).format('MMM')
}

function returnDayDate(date: string) {
  return moment(date).format('DD')
}

function getAndCapitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase()
}

function camelCase(string: string) {
  return string
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .normalize("NFD").replace(/[\u0300-\u036f]/g, '')
    .replace(/\W/g, '')
    .replace(/\s/g, '')
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
}

export {
  removeTags,
  truncate,
  cleanText,
  formatJobDate,
  returnDayDate,
  returnMonthDate,
  returnYearDate,
  getAndCapitalizeFirstLetter,
  extractGpsCoordinates,
  camelCase,
}
