import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex p-0 offer offer-section h-100 mt-3" }
const _hoisted_2 = { class: "offer-container h-100" }
const _hoisted_3 = { class: "header-job pt-4 pb-4" }
const _hoisted_4 = { class: "header title mb-1 d-flex justify-content-between" }
const _hoisted_5 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_6 = { class: "d-flex ref-position flex-column-reverse" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex justify-md-start justify-end localisation"
}
const _hoisted_8 = { class: "mb-0" }
const _hoisted_9 = { class: "header mb-8 d-flex justify-content-between" }
const _hoisted_10 = { class: "align-flex-start" }
const _hoisted_11 = { class: "d-flex flex-wrap" }
const _hoisted_12 = { class: "d-flex tag mr-2 align-center mb-1" }
const _hoisted_13 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_14 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_15 = {
  key: 0,
  class: "d-flex tag mr-2 align-center mb-1"
}
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = {
  key: 1,
  class: "d-flex tag mr-2 align-center mb-1"
}
const _hoisted_18 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_19 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_20 = { class: "d-flex tag align-center mr-2 mb-1" }
const _hoisted_21 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_22 = { class: "d-flex tag align-center mb-1" }
const _hoisted_23 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "job-content" }
const _hoisted_26 = {
  key: 0,
  class: "mb-12"
}
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 1,
  class: "mb-12"
}
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = {
  key: 2,
  class: "mb-12"
}
const _hoisted_31 = ["innerHTML"]
const _hoisted_32 = { class: "d-flex d-sm-flex d-md-none btn-offer-mobile" }
const _hoisted_33 = { class: "card-btn-offer" }
const _hoisted_34 = { class: "card-btn-offer" }
const _hoisted_35 = { class: "card-share" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_EmbedMap = _resolveComponent("EmbedMap")!
  const _component_FrontPageAds = _resolveComponent("FrontPageAds")!
  const _component_SimilarJobs = _resolveComponent("SimilarJobs")!
  const _component_ShareButton = _resolveComponent("ShareButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_MultiStepFormApplyModal = _resolveComponent("MultiStepFormApplyModal")!
  const _component_TarteAuCitron = _resolveComponent("TarteAuCitron")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "12",
              md: "8",
              class: "offer-details"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: "/jobs",
                    class: "back-offers text-decoration-none"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('page.job.backToOffers')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.job.title)
                      ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.job.title), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.job.city)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "mdi mdi-map-marker-outline" }, null, -1)),
                            _createElementVNode("p", _hoisted_8, _toDisplayString(`${_ctx.job.city}`), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "service mb-3" }, null, -1)),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mdi mdi-file-document-edit-outline mr-1" }, null, -1)),
                          (_ctx.job.contractType && _ctx.$te('page.job.contractType.' + _ctx.camelCase(_ctx.job.contractType.toLowerCase())))
                            ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t('page.job.contractType.' + _ctx.camelCase(_ctx.job.contractType.toLowerCase()))), 1))
                            : (_ctx.job.contractType)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.camelCase(_ctx.job.contractType.toLowerCase())), 1))
                              : _createCommentVNode("", true)
                        ]),
                        (_ctx.job.contractDurationUnit && _ctx.job.contractDurationValue)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mdi mdi-clock-outline mr-1" }, null, -1)),
                              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.job.contractDurationValue) + " " + _toDisplayString(_ctx.$tc('page.job.contractDurationUnit.' + _ctx.job.contractDurationUnit.toLowerCase(), _ctx.job.contractDurationValue > 1 ? 2 : 1 )), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.job.salaryMin || _ctx.job.salaryMax)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createVNode(_component_font_awesome_icon, {
                                icon: "fa-money-bill",
                                class: "mr-1"
                              }),
                              (_ctx.job.salaryMax)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('page.job.salary.from')) + " " + _toDisplayString(_ctx.job.salaryMin) + " " + _toDisplayString(_ctx.$t('page.job.salary.to')) + " " + _toDisplayString(_ctx.job.salaryMax) + " " + _toDisplayString(_ctx.$t('page.job.salary.currency')) + " " + _toDisplayString(_ctx.$t('page.job.salary.unit.' + _ctx.job.salaryUnit.toLowerCase())), 1))
                                : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.job.salaryMin) + " " + _toDisplayString(_ctx.$t('page.job.salary.currency')) + " " + _toDisplayString(_ctx.$t('page.job.salary.unit.' + _ctx.job.salaryUnit.toLowerCase())), 1))
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_20, [
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mdi mdi-calendar mr-1" }, null, -1)),
                          (_ctx.job.creationDate)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.$t('page.jobs.publishedOn')) + " " + _toDisplayString(_ctx.formatJobDate(_ctx.job.creationDate)), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          (_ctx.job.wid)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.$t('page.job.reference')) + " " + _toDisplayString(_ctx.job.wid), 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      (_ctx.job.logo && _ctx.client.properties.displayLogo)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "logo",
                            src: _ctx.job.logo
                          }, null, 8, _hoisted_24))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  (_ctx.job.descriptionCompany)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('page.job.title.brand-description')), 1),
                        _createElementVNode("div", {
                          class: "fs-9",
                          innerHTML: _ctx.job.descriptionCompany
                        }, null, 8, _hoisted_27)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.job.descriptionMission)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('page.job.title.job-description')), 1),
                        _createElementVNode("div", {
                          class: "fs-9",
                          innerHTML: _ctx.job.descriptionMission
                        }, null, 8, _hoisted_29)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.job.descriptionProfile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('page.job.title.profile-description')), 1),
                        _createElementVNode("div", {
                          class: "fs-9",
                          innerHTML: _ctx.job.descriptionProfile
                        }, null, 8, _hoisted_31)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_v_btn, {
                    class: "btn btn-primary btn-offer d-flex flex-wrap",
                    color: "primary",
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.apply()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('page.job.buttons.apply-offer')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createVNode(_component_v_col, {
              cols: "12",
              sm: "12",
              md: "4",
              class: "sidebar-job pt-2 pt-md-16"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_34, [
                    _createVNode(_component_v_btn, {
                      class: "btn btn-primary btn-offer d-flex flex-wrap",
                      color: "primary",
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.apply()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('page.job.buttons.apply-offer')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                (_ctx.client.properties.googleApiKey)
                  ? (_openBlock(), _createBlock(_component_EmbedMap, {
                      key: 0,
                      jobCity: _ctx.job.city,
                      jobLanguage: _ctx.job.language,
                      googleApiKey: _ctx.client.properties.googleApiKey
                    }, null, 8, ["jobCity", "jobLanguage", "googleApiKey"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_FrontPageAds),
                (_ctx.job.id)
                  ? (_openBlock(), _createBlock(_component_SimilarJobs, {
                      key: 1,
                      jobId: _ctx.job.id
                    }, null, 8, ["jobId"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('page.job.buttons.share-offer')), 1),
                  _createElementVNode("div", null, [
                    _createVNode(_component_ShareButton, {
                      link: `https://www.linkedin.com/shareArticle?mini=true&url=${_ctx.ssrJobDetailsUrl}`,
                      icon: "fa-brands fa-linkedin-in"
                    }, null, 8, ["link"]),
                    _createVNode(_component_ShareButton, {
                      link: `https://twitter.com/intent/tweet?text=${_ctx.job.title}&url=${_ctx.currentUrl}&via=${_ctx.client.name}'`,
                      icon: "fa-brands fa-twitter"
                    }, null, 8, ["link"]),
                    _createVNode(_component_ShareButton, {
                      link: `mailto:?subject=${_ctx.job.title}&body=${_ctx.currentUrl}`,
                      icon: "fa-envelope"
                    }, null, 8, ["link"]),
                    (_ctx.client.properties.facebookAppId)
                      ? (_openBlock(), _createBlock(_component_ShareButton, {
                          key: 0,
                          link: `https://www.facebook.com/dialog/share?app_id=${_ctx.client.properties.facebookAppId}&display=popup&href=${_ctx.currentUrl}&redirect_uri=${_ctx.currentUrl}`,
                          icon: "fa-brands fa-facebook-f"
                        }, null, 8, ["link"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_MultiStepFormApplyModal, {
        onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dataModal = false)),
        "show-modal": _ctx.dataModal,
        "job-id": _ctx.job.id
      }, null, 8, ["show-modal", "job-id"])
    ]),
    _createVNode(_component_TarteAuCitron)
  ], 64))
}